<template>
  <div id="viewVideo">
    <div v-if="normalShow">
      <div id="videoPlay" v-if="normalShow"></div>
      <img :src="imagesAddressCommon + '/xiazai.png'" class="xiazai" v-if="normalShow" @click.stop="toxiazai" />
      <div class="close" v-if="normalShow">
        <img src="@/common/img/DeTok/close.png" alt="" @click="goBack" />
      </div>
    </div>
    <div v-if="youtubeShow">
      <div id="iframePlayer"></div>
    </div>
  </div>
</template>

<script>
import Player from "xgplayer";
// import "videojs-landscape-fullscreen";
import { enumsImages } from "@/utils/enum.js";
export default {
  data() {
    return {
      // isshowfenxiang:true,
      normalShow: false,
      youtubeShow: false,
      showLoading: false,
      youtubePlayers: null,
      routerUrl: "",
      youtubeId: "",
      videoUrl: "",
      videolist: [],
      imagesAddressCommon: enumsImages.addressCommon,
      player: null,
    };
  },
  mounted() {
    // if(this.$route.query.youtubeId && this.$route.query.videoUrls && this.$route.query.routerUrl)
    // {
    //   this.youtubeId = this.$route.query.youtubeId;
    //   this.videoUrl = this.$route.query.videoUrls;
    //   this.routerUrl = this.$route.query.routerUrl;
    //   this.youtubePlayer();
    // }
    if (this.$route.query.videoUrls && this.$route.query.routerUrl) {
      this.youtubeId = this.$route.query.youtubeId;
      this.videoUrl = this.$route.query.videoUrls;
      this.routerUrl = this.$route.query.routerUrl;
      this.youtubePlayer();
    }
    this.videolist = [
      {
        name: "流畅",
        url: this.$route.query.readVideoUrl,
      },
      {
        name: "480p",
        url: this.$route.query.videoUrl480,
      },
      {
        name: "720p",
        url: this.$route.query.videoUrl720,
      },
      {
        name: "1080p",
        url: this.$route.query.videoUrl1080,
      },
    ];
  },
  methods: {
    goBack() {
      this.youtubeShow = false;
      this.normalShow = false;
      this.$router.back();
      try {
        setTimeout(() => {
          let path = window.location.hash.slice(1);
          this.$router.replace(path);
        }, 1);
        return false;
      } catch (e) {}
      // if(this.routerUrl == "home")
      // {
      //   this.$router.replace({path:"/"});
      // }
      // if(this.routerUrl == "videoList")
      // {
      //   this.$router.replace({
      //     path:"/videoList",
      //     query:{
      //       videoId:this.$route.query.videoId
      //     }
      //   });
      // }
    },
    youtubePlayer() {
      let _this = this;
      _this.youtubeShow = true;
      _this.normalShow = false;
      function onYouTubeIframeAPIReady() {
        _this.showLoading = false;
        //此处加载YouTube视频
        try {
          _this.youtubePlayers = new YT.Player("iframePlayer", {
            videoId: _this.youtubeId,
            playerVars: { hl: "zh-tw" },
            events: {
              onError: onError,
            },
          });
        } catch (error) {
          // console.log(error)
          _this.videoPlayer();
        }
      }
      // 加载失败 加载已上传视频
      function onError(error) {
        // console.log("onError",error)
        _this.videoPlayer();
      }
      // // 确保dom加载完毕
      setTimeout(() => {
        onYouTubeIframeAPIReady();
      }, 500);
    },
    videoPlayer() {
      let _this = this;
      this.showLoading = false;
      this.normalShow = true;
      this.youtubeShow = false;
      this.$nextTick(() => {
        this.player = new Player({
          id: "videoPlay",
          url: this.videoUrl,
          lang: "zh-cn",
          autoplay: true,
          playsinline: true,
          definitionActive: "click",
          // rotateFullscreen: true,
          fullScreen: true,
          height: window.innerHeight,
          width: window.innerWidth,
          // download: true //设置download控件显示
        });
        this.player.emit("resourceReady", this.videolist);
        this.player.on("requestFullscreen", function () {
          // 监听全屏旋转视频
          window.screen.lockOrientationUniversal("landscape");
        });
      });
    },
    // tofenxiang(){
    //     let _this=this
    //     var userAgent = navigator.userAgent;
    //     if (userAgent.indexOf("android") > -1) {
    //         window.Android.shareNewsFromWebsite(_this.videotitle,_this.videoUrl,_this.imgurl);
    //     } else if (/^.+(Mobile\/\w+)\s?$/.test(userAgent)) {
    //         let datas=JSON.stringify({fxtitle:_this.videotitle,fxurl:_this.videoUrl,fxcoverimg:_this.imgurl})
    //         window.webkit.messageHandlers.shareNewsToiPhone.postMessage(datas);
    //     }
    // },
    toxiazai() {
      this.downloadApp(this.videoUrl);
    },
    // 下载app 打开外链
    downloadApp(url) {
      let u = navigator.userAgent;
      let isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //android终端
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      if (isAndroid) {
        try {
          window.Android.downloadSeaAtoshi(url);
        } catch (error) {
          window.location.href = url;
        }
      } else if (isiOS) {
        try {
          window.webkit.messageHandlers.downloadSeaAtoshi.postMessage(url);
        } catch (error) {
          window.location.href = url;
        }
      } else {
        window.location.href = url;
      }
    },
  },
  beforeDestroy() {
    try {
      this.player.destroy();
    } catch (error) {
      // console.log(error)
    }
    try {
      this.youtubePlayers.destroy();
    } catch (error) {
      // console.log(error)
    }
  },
};
</script>

<style scoped>
#viewVideo {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background-color: #000;
  z-index: 999999999999;
}
video {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
#videoPlay {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.close{
  position:absolute;
  right: 0rem;
  top: 0rem;
  width: .8rem;
  height: .8rem;
}
.close img{
  width: 100%;
}
.fenxiang{
    width:0.7rem;
    height:0.7rem;
    position: fixed;
    bottom:1.1rem;
    right:0.3rem;
    z-index: 1000;
}
.xiazai{
    width:0.92rem;
    height:0.92rem;
    position: fixed;
    bottom:2rem;
    right:0.19rem;
    z-index:9999!important;
}
.van-overlay{
  z-index: 9999!important;
}
.van-loading
{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}
</style>
<style>
/* #viewVideo .xgplayer-skin-default .xgplayer-fullscreen, #viewVideo .xgplayer-skin-default .xgplayer-fullscreen-img
{
  display: none!important;
} */
/* #viewVideo .xgplayer-skin-default .xgplayer-controls {
    padding-right: 5%;
} */
#iframePlayer{
  position: fixed;
  top: 0.8rem;
  left: 0;
  width: 100%;
  height: calc(100% - 0.8rem);
  /* height: 100%; */
  min-height: 4rem;
  z-index: 9999;
}
</style>
